<template>
  <Spinner id="spinner"></Spinner>
  <div class="content">
    <Header @openSideBar="openSideBar"></Header>
    <SideBar
      :isSideBarOpen="getIsSideBarOpen"
      @closeSideBar="closeSideBar"
    ></SideBar>
    <router-view />
    <Footer></Footer>
    <ScrollTopArrow></ScrollTopArrow>
  </div>
</template>

<script>
import Header from "@/components/Header";
import SideBar from "@/components/SideBar";
import ScrollTopArrow from "@/components/ScrollTopArrow";
import Spinner from "@/components/Spinner";
import Footer from "@/components/Footer";
import { computed, ref } from "@vue/runtime-core";
import $ from "jquery";

export default {
  components: {
    Header,
    SideBar,
    ScrollTopArrow,
    Footer,
    Spinner,
  },
  setup() {
    const isSideBarOpen = ref(false);

    window.addEventListener("load", () => {
      $("#spinner").fadeOut();
      $("#app").removeClass("overflow-hidden");
    });

    function openSideBar() {
      isSideBarOpen.value = true;
    }

    function closeSideBar() {
      isSideBarOpen.value = false;
    }

    return {
      getIsSideBarOpen: computed(() => isSideBarOpen.value),
      openSideBar,
      closeSideBar,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading-page-enter-from {
  opacity: 0;
}
.loading-page-enter-to {
  opacity: 1;
}
.loading-page-active {
  transition: opacity 0.3s ease-in-out;
}
</style>