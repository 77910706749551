<template>
  <footer class="site-footer">
    <div class="absolute-huge-circle">
      <img
        src="@/assets/img/utilities/blob.svg"
        alt="fondo-footer"
        loading="lazy"
      />
    </div>
    <div class="footer-content container">
      <a href="#" class="logo">
        <div class="image">
          <img
            src="@/assets/img/utilities/logo.png"
            alt="Fancy Solutions"
            loading="lazy"
          />
        </div>
        <div class="description">
          <span>Fancy</span>
          <span>Solutions</span>
        </div>
      </a>
      <div class="by-footer">
        <p>
          Transformamos tu negocio con soluciones digitales adaptables que
          satisfacen las necesidades de hoy y desbloquean las oportunidades del
          mañana.
        </p>
      </div>
      <ul class="footer-socials-list">
        <li>
          <a href="mailto:info@fancy-solutions.com" class="btn-social"
            ><i class="fas fa-envelope"></i
          ></a>
        </li>
        <li>
          <a href="tel:3757 680001" class="btn-social">
            <i class="fas fa-phone-alt"></i>
          </a>
        </li>
        <li>
          <a
            href="https://api.whatsapp.com/send/?phone=5493757680002&text=Hola,+estoy+interesado/a+en+sus+servicios.&app_absent=0"
            class="btn-social"
          >
            <i class="fab fa-whatsapp"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/fancy_solutions/"
            target="_black"
            class="btn-social"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="footer-bottom-bar">
      <div class="container">
        <nav class="footer-bottom-nav">
          <a href="#hero-section">Inico</a>
          <a href="#about-us-section">Nosotros</a>
          <a href="#developers-section">Nuestro Equipo</a>
          <a href="#technologies-section">Tecnologias</a>
          <a href="#contact-us-section">Contacto</a>
        </nav>
        <div class="footer-copyright">
          <p>
            &copy; Copyright by
            <span class="highlighted"
              >Fancy Solutions {{ new Date().getFullYear() }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.site-footer {
  background: $backgroundGradient;
  padding-top: 35px;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 75px;
  }

  .absolute-huge-circle {
    position: absolute;
    right: 0;
    left: 200px;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 0;

    @media (min-width: 1100px) {
      left: 55%;
    }

    img {
      transform: translate(150px, 180px) scale(1.6);
      animation: opacity-circle 1s alternate infinite;

      @keyframes opacity-circle {
        from {
          opacity: 0.5;
        }
        to {
          opacity: 1;
        }
      }

      @media (min-width: 1100px) {
        transform: translateX(200px) scale(1.5);
      }
    }
  }

  .footer-content {
    position: relative;
    z-index: 2;

    .logo {
      transition: transform 0.3s ease-in-out;
      display: flex;
      align-items: center;
      text-decoration: none;

      .image {
        height: 50px;
        width: 50px;
      }

      .description {
        margin-left: 10px;
        text-decoration: none;

        span {
          display: block;
          font-weight: 600;
          transition: color 0.3s ease-in-out;

          &:first-child {
            font-size: 18px;
            color: $white;
          }
          &:last-child {
            font-size: 17px;
            color: $primaryGreen;
          }
        }
      }
    }

    .by-footer {
      margin-top: 20px;
      color: $white;
      max-width: 500px;

      p {
        font-size: 14px;
      }
    }

    .footer-socials-list {
      display: flex;
      align-items: center;
      margin: 30px auto 0 auto;
      list-style: none;

      li {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        .btn-social {
          display: inline-block;
          background-color: $primaryBlue;
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          color: $white;
          box-shadow: 0px 0px 0px 0px $white;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

          &:hover {
            transition: background-color 0.3s ease-in-out,
              color 0.3s ease-in-out, box-shadow 0.5s ease-in-out;
            background-color: $white;
            color: $darkBlue;
            box-shadow: 0px 0px 0px 10px transparent;
          }
        }
      }
    }
  }

  .footer-bottom-bar {
    width: 100%;
    padding: 25px 0;
    margin-top: 60px;
    font-size: 15px;
    border-top: 1px solid #2a355e;
    position: relative;
    z-index: 2;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footer-bottom-nav {
        display: none;

        @media (min-width: 950px) {
          display: inline-block;
        }

        a {
          text-decoration: none;
          color: $white;
          margin-right: 20px;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: $lightGray;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .footer-copyright {
        text-align: left;
        color: $white;
        margin-left: 0;

        @media (min-width: 950px) {
          margin-left: 70px;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 2;

          .highlighted {
            color: $primaryGreen;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>