import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "aos/dist/aos.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueTilt);
app.use(VueSweetalert2);

app.mount("#app");
