<template>
  <transition
    @after-enter="openSide"
    @click="closeSide"
    :name="
      getIsSideBarOpen
        ? 'sidebar-opening-container'
        : 'sidebar-closing-container'
    "
  >
    <div
      v-if="getIsSideBarOpen"
      id="sidebar-container"
      class="sidebar-container"
    >
      <transition
        @after-leave="closeSideBarChild"
        :name="getIsSideOpen ? 'side-opening' : 'side-closing'"
      >
        <aside v-if="getIsSideOpen" class="sidebar">
          <div class="title">
            <a @click="closeSideChild" href="#" class="logo">
              <img
                src="@/assets/img/utilities/logo.png"
                alt="Fancy Solutions"
                loading="lazy"
              />
            </a>
            <button @click="closeSideChild" class="close-btn">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <nav class="navigation">
            <a @click="closeSideChild" href="#hero-section">
              <i class="fas fa-home"></i>
              <p>Inicio</p>
            </a>
            <a @click="closeSideChild" href="#services-section">
              <i class="fas fa-tasks"></i>
              <p>Servicios</p>
            </a>
            <a @click="closeSideChild" href="#about-us-section">
              <i class="fas fa-info"></i>
              <p>Nosotros</p>
            </a>
            <a @click="closeSideChild" href="#developers-section">
              <i class="fas fa-code"></i>
              <p>Nuestro Equipo</p>
            </a>
            <a @click="closeSideChild" href="#technologies-section">
              <i class="fas fa-code-branch"></i>
              <p>Tecnologias</p>
            </a>
            <a @click="closeSideChild" href="#projects-section">
              <i class="fas fa-project-diagram"></i>
              <p>Proyectos</p>
            </a>
            <a @click="closeSideChild" href="#frequent-questions-section">
              <i class="fas fa-question"></i>
              <p>Preguntas Frecuentes</p>
            </a>
            <a
              @click="closeSideChild"
              href="#contact-us-section"
            >
              <i class="fas fa-phone-alt"></i>
              <p>Contacto</p>
            </a>
          </nav>
        </aside>
      </transition>
    </div>
  </transition>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";

export default {
  props: ["isSideBarOpen"],
  emits: ["closeSideBar"],
  setup(props, context) {
    const isSideOpen = ref(false);

    function openSide() {
      isSideOpen.value = true;
    }

    function closeSide(e) {
      if (e.target.id == "sidebar-container") {
        closeSideChild();
      }
    }

    function closeSideChild() {
      isSideOpen.value = false;
    }

    function closeSideBarChild() {
      context.emit("closeSideBar");
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth > 900 && isSideOpen.value == true) {
        closeSideChild();
      }
    });

    return {
      getIsSideBarOpen: computed(() => props.isSideBarOpen),
      getIsSideOpen: computed(() => isSideOpen.value),
      openSide,
      closeSide,
      closeSideBarChild,
      closeSideChild,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar-opening-container-enter-from,
.sidebar-opening-container-leave-from,
.sidebar-closing-container-enter-to,
.sidebar-closing-container-leave-to {
  opacity: 0;
}
.sidebar-opening-container-enter-to,
.sidebar-opening-container-leave-to,
.sidebar-closing-container-enter-from,
.sidebar-closing-container-leave-from {
  opacity: 1;
}
.sidebar-opening-container-enter-active,
.sidebar-opening-container-leave-active,
.sidebar-closing-container-enter-active,
.sidebar-closing-container-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.side-opening-enter-from,
.side-opening-leave-from,
.side-closing-enter-to,
.side-closing-leave-to {
  transform: translateX(-100%);
}
.side-opening-enter-to,
.side-opening-leave-to,
.side-closing-enter-from,
.side-closing-leave-from {
  transform: translateX(0);
}
.side-opening-enter-active,
.side-opening-leave-active .side-closing-enter-active,
.side-closing-leave-active {
  transition: transform 0.3s ease-in-out;
}

.click-disabled {
  pointer-events: none;
}
.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.671);
  z-index: 10;

  ::-webkit-scrollbar {
    width: 0;
  }

  .sidebar {
    background-color: $white;
    width: 80%;
    max-width: 300px;
    height: 100%;
    background-color: rgb(51, 51, 51);
    overflow-y: auto;
    padding: 30px 0;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 20px;

      .logo {
        width: 50px;
        height: 50px;
      }

      .close-btn {
        display: inline-block;
        height: 40px;
        width: 40px;
        color: $white;
        text-align: center;
        line-height: 40px;
        font-size: 17px;
        border-radius: 50%;
        background-color: $darkGray;
        box-shadow: 0px 0px 0px 0px $white;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          transition: background-color 0.3s ease-in-out,
            box-shadow 0.5s ease-in-out;
          background-color: $primaryBlue;
          box-shadow: 0px 0px 0px 10px transparent;
        }
      }
    }

    .navigation {
      padding: 10px;

      a {
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 12px 15px;
        margin-bottom: 12px;
        color: #c2c7d0;
        text-decoration: none;
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: #494e53;
          color: $white;
        }
        i {
          margin-right: 15px;
          flex: 0 0 20px;
        }
        p {
          font-size: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>