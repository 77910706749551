<template>
  <button
    @click="scrollUp"
    :class="{ 'arrow-visible': isButtonVisible }"
    class="scroll-top-arrow"
  >
    <i class="fas fa-chevron-up"></i>
  </button>
</template>

<script>
import { computed, ref } from "@vue/reactivity";

export default {
  setup() {
    const isButtonVisible = ref(false);

    window.addEventListener("scroll", () => {
      if (window.scrollY <= 400) {
        isButtonVisible.value = false;
      } else {
        isButtonVisible.value = true;
      }
    });

    function scrollUp() {
      window.scrollTo({ top: 0 });
    }

    return {
      isButtonVisible: computed(() => isButtonVisible.value),
      scrollUp,
    };
  },
};
</script>

<style lang="scss" scoped>
.arrow-visible {
  transform: scale(1) !important;
}

.scroll-top-arrow {
  transform: scale(0);
  z-index: 4;
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: $primaryBlue;
  color: $white;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 0px $darkBlue;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      box-shadow 0.5s ease-in-out;
    background-color: $darkBlue;
    box-shadow: 0px 0px 0px 10px transparent;
  }
}
</style>
