<template>
  <header :class="{ 'active-header': getIsHeaderActive }">
    <div class="container">
      <div class="header-container">
        <a href="#" class="logo">
          <div class="image">
            <img src="@/assets/img/utilities/logo.png" alt="Fancy Solutions" loading="lazy"/>
          </div>
          <div class="description">
            <span>Fancy</span>
            <span>Solutions</span>
          </div>
        </a>
        <button @click="openSideBar" class="hamburger-menu">
          <i class="fas fa-bars"></i>
        </button>
        <nav>
          <a href="#hero-section">Inico</a>
          <a href="#about-us-section">Nosotros</a>
          <a href="#developers-section">Nuestro Equipo</a>
          <a href="#technologies-section">Tecnologias</a>
          <a href="#contact-us-section" class="btn btn-filled">Contacto</a>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { computed, ref } from "@vue/reactivity";

export default {
  emits: ["openSideBar"],
  setup(_, context) {
    const isHeaderActive = ref(false);

    window.addEventListener("scroll", () => {
      headerActive();
    });

    function headerActive() {
      if (window.scrollY <= 0) {
        isHeaderActive.value = false;
      } else {
        isHeaderActive.value = true;
      }
    }

    headerActive();

    function openSideBar() {
      context.emit("openSideBar");
    }

    return {
      getIsHeaderActive: computed(() => isHeaderActive.value),
      openSideBar,
    };
  },
};
</script>

<style lang="scss" scoped>
.active-header {
  background-color: #ffffffe6;
  padding: 20px 0;
  box-shadow: -2px 1px 30px 2px rgba(134, 134, 134, 0.322);
  backdrop-filter: blur(6px);

  .header-container {
    .logo {
      transform: scale(0.92);

      .description {
        margin-left: 10px;
        text-decoration: none;

        span {
          &:first-child {
            color: $darkGray;
          }
          &:last-child {
            color: $primaryBlue;
          }
        }
      }
    }
    .hamburger-menu {
      box-shadow: 0px 0px 0px 0px $darkBlue;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        transition: background-color 0.3s ease-in-out,
          box-shadow 0.5s ease-in-out;
        box-shadow: 0px 0px 0px 10px transparent;
      }
    }

    nav {
      a {
        color: $darkBlue;

        &:hover {
          color: $primaryBlue;
        }
      }
      .btn-filled {
        &:hover {
          color: $primaryBlue !important;
        }
      }
    }
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  padding: 30px 0;
  transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      transition: transform 0.3s ease-in-out;
      display: flex;
      align-items: center;
      text-decoration: none;

      .image {
        height: 50px;
        width: 50px;
      }

      .description {
        margin-left: 10px;
        text-decoration: none;

        span {
          display: block;
          font-weight: 600;
          transition: color 0.3s ease-in-out;

          &:first-child {
            font-size: 18px;
            color: $white;
          }
          &:last-child {
            font-size: 17px;
            color: $primaryGreen;
          }
        }
      }
    }

    .hamburger-menu {
      display: inline-block;
      height: 45px;
      width: 45px;
      color: $white;
      text-align: center;
      line-height: 45px;
      font-size: 16px;
      border-radius: 50%;
      background-color: $darkBlue;
      box-shadow: 0px 0px 0px 0px $white;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        transition: background-color 0.3s ease-in-out,
          box-shadow 0.5s ease-in-out;
        background-color: $primaryBlue;
        box-shadow: 0px 0px 0px 10px transparent;
      }

      @media (min-width: 900px) {
        display: none;
      }
    }

    nav {
      display: none;

      @media (min-width: 900px) {
        display: flex;
        align-items: center;
      }

      a {
        color: $lightGray;
        font-size: 16px;
        margin-right: 40px;
        text-decoration: none;
        display: inline-block;
        font-weight: 500;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: $white;
        }

        &:last-child {
          margin-right: 0;
        }
      }
      .btn {
        &:hover {
          color: $white !important;
        }
      }
    }
  }
}
</style>